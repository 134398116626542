import React from 'react';
import { IconButton, styled } from '@mui/material';

import { ReactComponent as Left } from 'content/images/icons/chevron-left.svg';
import { ReactComponent as Right } from 'content/images/icons/chevron-right.svg';

const ArrowRoot = styled('div', {
	shouldForwardProp: (prop) => prop !== 'small',
})<{
	small?: boolean;
}>(({ theme, small }) => ({
	padding: small ? 0 : theme.spacing(0, 5),
	[theme.breakpoints.down('lg')]: {
		padding: small ? 0 : theme.spacing(0, 3),
	},
}));

const ArrowIconButton = styled(IconButton, {
	shouldForwardProp: (prop) => prop !== 'small',
})<{ active?: boolean; small?: boolean }>(({ theme, small }) => ({
	position: 'relative',
	width: small ? 15 : 100,
	height: small ? 15 : 100,
	padding: small ? theme.spacing(2) : 0,
}));

const LeftArrow = styled(Left, { shouldForwardProp: (prop) => prop !== 'active' })<{
	active?: boolean;
}>(({ theme, active }) => ({
	height: 50,
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	// eslint-disable-next-line
	fill: active ? theme.palette.text.primary : theme.palette.disabled.dark,
}));

const RightArrow = styled(Right, { shouldForwardProp: (prop) => prop !== 'active' })<{
	active?: boolean;
}>(({ theme, active }) => ({
	height: 50,
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	// eslint-disable-next-line
	fill: active ? theme.palette.text.primary : theme.palette.disabled.dark,
}));

export interface ArrowProps {
	direction: 'left' | 'right';
	active?: boolean;
	handleClick?: React.MouseEventHandler<HTMLButtonElement>;
}

interface Props extends ArrowProps {
	small?: boolean;
	handleMouseDown?: React.MouseEventHandler<HTMLButtonElement> | undefined;
	handleMouseUp?: React.MouseEventHandler<HTMLButtonElement> | undefined;
	handleMouseLeave?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export default (props: Props) => {
	const selectDirection = () => {
		if (props.direction === 'left') {
			return <LeftArrow />;
		}
		return <RightArrow />;
	};

	return (
		<ArrowRoot>
			<ArrowIconButton
				// onMouseDown={props?.handleMouseDown}
				// onMouseUp={props?.handleMouseUp}
				// onMouseLeave={props?.handleMouseLeave}
				disabled={!props.active}
				onClick={props?.handleClick}
			>
				{selectDirection()}
			</ArrowIconButton>
		</ArrowRoot>
	);
};
