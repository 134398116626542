import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { styled, Typography } from '@mui/material';

import type { GalleryData } from 'components/Gallery/types/GalleryData';
import Flex from 'components/Flex';
import Content from 'components/Gallery/Content';
// import Panel from 'components/Gallery/Panel';

const GalleryRoot = styled(Flex)(({ theme }) => ({
	background: theme.palette.background.paper,
	padding: theme.spacing(10, 0),
	[theme.breakpoints.down('lg')]: {
		flexDirection: 'column',
		alignItems: 'center',
	},
}));

export default () => {
	const [activeIndex, setActiveIndex] = useState(0);

	const data: GalleryData = useStaticQuery(graphql`
		query GalleryContent {
			allWpGallery {
				nodes {
					title
					id
					galleryItem {
						subtitle
						image {
							localFile {
								childImageSharp {
									gatsbyImageData
								}
							}
							altText
							id
						}
					}
				}
			}
		}
	`);

	return (
		<GalleryRoot width direction="column" justify="center" align="center" id="gallery">
			<Typography variant="h2" marginBottom={4}>
				Gallery
			</Typography>
			<Content
				activeIndex={activeIndex}
				setActiveIndex={setActiveIndex}
				nodes={data?.allWpGallery?.nodes}
			/>
		</GalleryRoot>
	);
};
