import React, { useRef, useEffect } from 'react';
import { styled } from '@mui/material';

import Slider, { SliderProps } from 'components/Carousel/Slider';
import Flex from 'components/Flex';

const CarouselRoot = styled(Flex)({
	position: 'relative',
	width: '100%',
});

interface Props extends SliderProps {
	sliderRef: string;
	sliderClassName?: string;
	goToIndex?: number;
}

export default (props: Props) => {
	const sliderRef = useRef();

	// todo - Clean up types
	const handleArrowClick = (direction: 'next' | 'prev') => {
		if (sliderRef?.current) {
			if (sliderRef?.current?.slickNext && direction === 'next') {
				return sliderRef.current.slickNext();
			}
			if (sliderRef?.current?.slickPrev && direction === 'prev') {
				return sliderRef.current.slickPrev();
			}
			return null;
		}
		return null;
	};

	useEffect(() => {
		if (typeof props?.goToIndex === 'number') {
			sliderRef?.current?.slickGoTo(props.goToIndex);
		}
	}, [props?.goToIndex]);

	return (
		<CarouselRoot>
			<Slider
				ref={sliderRef}
				className={props?.sliderClassName}
				noAutoplay={props?.noAutoplay}
				noMinHeight={props?.noMinHeight}
				setActiveIndex={props?.setActiveIndex}
				handleClick={handleArrowClick}
			>
				{props?.children}
			</Slider>
		</CarouselRoot>
	);
};
