import React from 'react';
import { styled } from '@mui/material';

import IconText from 'components/IconText';
import Flex from 'components/Flex';

const Container = styled(Flex)(({ theme }) => ({
	margin: theme.spacing(0, 3, 0, 0),
	[theme.breakpoints.down('sm')]: {
		margin: 0,
	},
}));

const BusinessInfoIconsRoot = styled(Flex)(({ theme }) => ({
	[theme.breakpoints.down('sm')]: {
		flexDirection: 'column',
	},
}));

interface Props {
	iconsBottom?: boolean;
	dark?: boolean;
}

export default (props: Props) => {
	const email = 'info@atlasscaffolding.uk';
	const phone = '01227 49 00 94';
	const mobile = '07942 34 83 48';

	if (!props?.iconsBottom) {
		return (
			<BusinessInfoIconsRoot>
				<Container direction="column" defaultFlex>
					<IconText
						content={email}
						type="email"
						dark={props?.dark}
						link={`mailto:${email}`}
					/>
					<IconText
						content={phone}
						type="phoneOffice"
						dark={props?.dark}
						link={`tel:${phone}`}
					/>
					<IconText
						content={mobile}
						type="phone"
						dark={props?.dark}
						link={`tel:${mobile}`}
					/>
				</Container>
			</BusinessInfoIconsRoot>
		);
	}

	return (
		<>
			<IconText content={email} type="email" dark={props?.dark} />
			<IconText content={phone} type="phoneOffice" dark={props?.dark} />
			<IconText content={mobile} type="phone" dark={props?.dark} />
		</>
	);
};
