import React, { forwardRef, ReactNode } from 'react';
import { styled, useTheme, useMediaQuery } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Arrow from 'components/Carousel/Arrow';

const Carousel = styled(Slider, { shouldForwardProp: (prop) => prop !== 'noMinHeight' })<{
	noMinHeight?: boolean;
}>(({ theme, noMinHeight }) => ({
	maxWidth: '100%',
	display: 'flex',
	alignItems: 'center',
	[theme.breakpoints.down('sm')]: {
		minHeight: noMinHeight ? 'auto' : 300,
	},
}));

export interface SliderProps {
	noAutoplay?: boolean;
	noMinHeight?: boolean;
	children: ReactNode;
	// Function to allow thumbnail gallery to update correctly on change
	setActiveIndex?: (index: number) => void;
}

interface Props extends SliderProps {
	className?: string;
	handleClick: (direction: 'next' | 'prev') => void;
}

export default forwardRef((props: Props, ref: React.ForwardedRef<any>) => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('md'));

	const settings = {
		dots: false,
		infinite: true,
		autoplay: !props?.noAutoplay,
		arrows: !mobile,
		nextArrow: (
			<Arrow direction="right" active handleClick={() => props?.handleClick('next')} />
		),
		prevArrow: <Arrow direction="left" active handleClick={() => props?.handleClick('prev')} />,
	};

	return (
		<Carousel
			{...settings}
			className={`overflow-hidden ${props?.className}`}
			ref={ref}
			adaptiveHeight
			noMinHeight={props?.noMinHeight}
			beforeChange={
				props?.setActiveIndex
					? (oldIndex, newIndex) => {
							props?.setActiveIndex(newIndex);
					  }
					: null
			}
		>
			{props?.children}
		</Carousel>
	);
});
