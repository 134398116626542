import React from 'react';
import { styled } from '@mui/material';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';

const BackgroundImage = styled(BgImage, { shouldForwardProp: (prop) => prop !== 'navPadding' })<{
	navPadding?: boolean;
}>(({ theme, navPadding }) => ({
	display: 'flex',
	alignItems: 'center',
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	width: '100%',
	padding: navPadding ? theme.spacing(12, 0, 18) : theme.spacing(12, 0),
	[theme.breakpoints.down('sm')]: {
		padding: theme.spacing(5, 3),
	},
	'& a': {
		textDecoration: 'none',
	},
}));

interface Props {
	image: IGatsbyImageData;
	children: React.ReactNode;
	className?: string;
	navPadding?: boolean;
	lighter?: boolean;
	id?: string;
}

export default (props: Props) => {
	const opacity = props?.lighter ? '0.3' : '0.5';

	const backgroundStack = [
		`linear-gradient(rgba(0,0,0,${opacity}), rgba(0,0,0,${opacity}))`,
		props?.image,
	];

	return (
		<BackgroundImage
			image={backgroundStack}
			className={props?.className}
			navPadding={props?.navPadding}
			id={props?.id}
		>
			{props?.children}
		</BackgroundImage>
	);
};
