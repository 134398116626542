import React from 'react';

import { Typography, styled } from '@mui/material';

import Flex from 'components/Flex';

const Text = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'small' && prop !== 'dark',
})<{
	small?: boolean;
	dark?: boolean;
}>(({ theme, small, dark }) => ({
	color: dark ? theme.palette.text.primary : theme.palette.disabled.main,
	fontSize: small ? '0.7rem' : 'inherit',
}));

export interface TextProps {
	content: string | string[];
	small?: boolean;
	dark?: boolean;
}

export default (props: TextProps) => {
	const renderString = (string: string, paragraph?: boolean) => (
		<Text key={string} paragraph={paragraph} small={props?.small} dark={props?.dark}>
			{string}
		</Text>
	);

	const renderParagraph = (arr: string[]) => (
		<Flex direction="column">
			{arr?.map((string, index) => renderString(string, index === arr.length - 1))}
		</Flex>
	);

	if (Array?.isArray(props?.content)) {
		return renderParagraph(props?.content);
	}
	return renderString(props?.content as string);
};
