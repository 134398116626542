import React from 'react';
import { styled } from '@mui/material';
import { IGatsbyImageData } from 'gatsby-plugin-image';

import Flex from 'components/Flex';
import ContentImage from 'components/About/ContentImage';

const AboutRoot = styled(Flex)(({ theme }) => ({
	padding: theme.spacing(5, 10),
	[theme.breakpoints.down('sm')]: {
		padding: theme.spacing(5, 3),
	},
}));

interface Block {
	title: string;
	content: string;
	image: IGatsbyImageData;
	imageAlt: string;
}

interface Props {
	block1: Block;
	block2: Block;
	block3: Block;
}

export default (props: Props) => {
	const renderContent = () =>
		[props?.block1, props?.block2, props?.block3]?.map((e, index) => (
			<ContentImage {...e} reverse={index % 2 !== 0} key={e?.title} />
		));

	return (
		<AboutRoot direction="column" id="about" align="center">
			{renderContent()}
		</AboutRoot>
	);
};
