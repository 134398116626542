import React from 'react';
import { graphql } from 'gatsby';

import BaseLayout from 'layouts/Base';
import Hero from 'components/Hero';
import About from 'components/About';
import Contact from 'components/Contact';
import Gallery from 'components/Gallery';
import type { WordpressImage } from 'types/wordpressImage';
import Services from 'components/Services';

interface HomepageData {
	wpPage: {
		homepage: {
			heroTitle: string;
			heroSubtitle: string;
			heroImage: WordpressImage;
			block1Title: string;
			block1Content: string;
			block1Image: WordpressImage;
			block2Title: string;
			block2Content: string;
			block2Image: WordpressImage;
			block3Title: string;
			block3Content: string;
			block3Image: WordpressImage;
			contactSectionTitle: string;
		};
		seo: {
			title: string;
			metaDesc: string;
		};
	};
}

export default ({ data }: { data: HomepageData }) => {
	const page = data?.wpPage?.homepage;

	return (
		<BaseLayout
			title={data?.wpPage?.seo?.title}
			metaTitle={data?.wpPage?.seo?.title}
			metaDescription={data?.wpPage?.seo?.metaDesc}
		>
			<Hero
				backgroundImage={page?.heroImage?.localFile?.childImageSharp?.gatsbyImageData}
				title={page?.heroTitle}
				subtitle={page?.heroSubtitle}
			/>
			<Services />
			<About
				block1={{
					title: page?.block1Title,
					content: page?.block1Content,
					image: page?.block1Image?.localFile?.childImageSharp?.gatsbyImageData,
					imageAlt: page?.block1Image?.altText,
				}}
				block2={{
					title: page?.block2Title,
					content: page?.block2Content,
					image: page?.block2Image?.localFile?.childImageSharp?.gatsbyImageData,
					imageAlt: page?.block2Image?.altText,
				}}
				block3={{
					title: page?.block3Title,
					content: page?.block3Content,
					image: page?.block3Image?.localFile?.childImageSharp?.gatsbyImageData,
					imageAlt: page?.block3Image?.altText,
				}}
			/>
			<Gallery />
			<Contact contactInfoTitle={page?.contactSectionTitle} />
		</BaseLayout>
	);
};

export const data = graphql`
	query HomePage {
		wpPage(id: { eq: "cG9zdDo0OA==" }) {
			homepage {
				heroTitle
				heroSubtitle
				heroImage {
					localFile {
						childImageSharp {
							gatsbyImageData
						}
					}
					altText
				}
				block1Title
				block1Content
				block1Image {
					localFile {
						childImageSharp {
							gatsbyImageData
						}
					}
					altText
				}
				block2Title
				block2Content
				block2Image {
					localFile {
						childImageSharp {
							gatsbyImageData
						}
					}
					altText
				}
				block3Title
				block3Content
				block3Image {
					localFile {
						childImageSharp {
							gatsbyImageData
						}
					}
					altText
				}
				contactSectionTitle
			}
			seo {
				title
				metaDesc
			}
		}
	}
`;
