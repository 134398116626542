import React from 'react';
import { styled } from '@mui/material';
import { useStaticQuery, graphql } from 'gatsby';

import Title from 'components/Title';
import BusinessInfoIcons from 'components/BusinessInfoIcons';
import LeftAlignedContainer from 'components/LeftAlignedContainer';
import DarkenedBackgroundImage from 'components/DarkenedBackgroundImage';
import type { LocalImageFile } from 'types/localImageFile';

const SectionTitle = styled(Title)(({ theme }) => ({
	marginBottom: theme.spacing(1),
}));

interface Props {
	contactInfoTitle: string;
	contactInfoSubtitle?: string | null;
}

export default (props: Props) => {
	const data: LocalImageFile = useStaticQuery(graphql`
		query Contact {
			file(relativePath: { eq: "stock/2-large.jpg" }) {
				id
				childImageSharp {
					gatsbyImageData
				}
				name
			}
		}
	`);

	return (
		<DarkenedBackgroundImage
			image={data?.file?.childImageSharp?.gatsbyImageData}
			lighter
			id="contact"
		>
			<LeftAlignedContainer>
				<SectionTitle
					title={props?.contactInfoTitle}
					subtitle={props?.contactInfoSubtitle}
					left
					white
					small
				/>
				<BusinessInfoIcons />
			</LeftAlignedContainer>
		</DarkenedBackgroundImage>
	);
};
