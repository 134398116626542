import React from 'react';
import { styled } from '@mui/material';

const Divider = styled('hr', {
	shouldForwardProp: (prop) => prop !== 'white' && prop !== 'vertical' && prop !== 'small',
})<{
	white?: boolean;
	vertical?: boolean;
	small?: boolean;
}>(({ theme, white, vertical, small }) => ({
	height: vertical ? 50 : 2,
	width: vertical ? 2 : 150,
	color: white ? 'white' : theme.palette.primary.main,
	margin: vertical
		? small
			? theme.spacing(0, 1)
			: theme.spacing(0, 2)
		: small
		? theme.spacing(1, 0)
		: theme.spacing(2, 0),
}));

interface Props {
	white?: boolean;
	vertical?: boolean;
	className?: string;
	small?: boolean;
}

export default (props: Props) => (
	<Divider
		white={props?.white}
		vertical={props?.vertical}
		className={props?.className}
		small={props?.small}
	/>
);
