import React from 'react';
import { Typography, styled } from '@mui/material';

import Flex from 'components/Flex';
import Divider from 'components/Divider';

const Title = styled(Typography)(({ theme }) => ({
	marginBottom: theme.spacing(1),
}));

const Subtitle = styled(Typography, { shouldForwardProp: (prop) => prop !== 'small' })<{
	small?: boolean;
}>(({ small }) => ({
	fontSize: small ? '1rem' : 'inherit',
	lineHeight: small ? 1.25 : 'inherit',
}));

interface Props {
	title?: string;
	subtitle?: string;
	className?: string;
	secondary?: boolean;
	flexStart?: boolean;
	small?: boolean;
	line?: boolean;
}

export default (props: Props) => {
	const renderTitle = () => {
		if (props?.title && props?.title !== null) {
			return (
				<Title
					variant={props?.small ? 'h3' : 'h4'}
					color="secondary"
					align={props?.flexStart ? 'left' : 'center'}
				>
					{props?.title}
				</Title>
			);
		}
		return null;
	};

	const renderSubtitle = () => {
		if (props?.subtitle && props?.subtitle !== null) {
			return (
				<Subtitle
					variant="subtitle1"
					align={props?.flexStart ? 'left' : 'center'}
					small={props?.small}
				>
					{props?.subtitle}
				</Subtitle>
			);
		}
		return null;
	};

	const renderDivider = () => (props?.line ? <Divider small /> : null);

	return (
		<Flex
			direction="column"
			align={props?.flexStart ? 'flex-start' : 'center'}
			className={props?.className}
			width
		>
			{renderTitle()}
			{renderDivider()}
			{renderSubtitle()}
		</Flex>
	);
};
