import React from 'react';
import { styled, Typography } from '@mui/material';
import { useStaticQuery, graphql } from 'gatsby';

import Flex from 'components/Flex';
import Service from 'components/Services/Service';

const ServicesRoot = styled(Flex)(({ theme }) => ({
	padding: theme.spacing(10, 0),
	background:
		'linear-gradient(0deg, rgba(241,241,241,1) 0%, rgba(241,241,241,1) 30%, rgba(255,255,255,1) 30%, rgba(255,255,255,1) 100%)',
	[theme.breakpoints.down('md')]: {
		padding: theme.spacing(5, 0),
	},
}));

const ServicesContainer = styled(Flex)(({ theme }) => ({
	justifyContent: 'space-evenly',
	alignItems: 'stretch',
	[theme.breakpoints.down('md')]: {
		flexDirection: 'column',
		justifyContent: 'center',
	},
}));

interface Data {
	allWpService: {
		nodes: {
			service: {
				content: string;
				icon: {
					localFile: {
						publicURL: string;
					};
				};
				serviceOrder: number;
			};
			title: string;
		}[];
	};
}

export default () => {
	const data: Data = useStaticQuery(graphql`
		query Services {
			allWpService {
				nodes {
					service {
						content
						icon {
							localFile {
								publicURL
							}
						}
						serviceOrder
					}
					title
				}
			}
		}
	`);

	const renderServices = () =>
		data?.allWpService?.nodes
			?.sort((a, b) => a.service.serviceOrder - b.service.serviceOrder)
			?.map((e) => (
				<Service
					title={e?.title}
					content={e?.service?.content}
					icon={e?.service?.icon?.localFile?.publicURL}
					key={`${e?.title}${e?.service?.content}`}
				/>
			));

	return (
		<ServicesRoot direction="column" id="services" align="center">
			<Typography variant="h2" textAlign="center" marginBottom={4}>
				Services
			</Typography>
			<ServicesContainer>{renderServices()}</ServicesContainer>
		</ServicesRoot>
	);
};
