import React from 'react';
import { Typography, styled } from '@mui/material';
import { IGatsbyImageData } from 'gatsby-plugin-image';

import Flex from 'components/Flex';
import ContentImage from 'components/ContentImage';
import Paragraphs from 'components/Paragraphs';

const TextContainer = styled(Flex, { shouldForwardProp: (prop) => prop !== 'reverse' })<{
	reverse?: boolean;
}>(({ theme, reverse }) => ({
	margin: reverse ? theme.spacing(0, 0, 0, 10) : theme.spacing(0, 10, 0, 0),
	[theme.breakpoints.down('md')]: {
		margin: 0,
	},
}));

export interface ContentImageProps {
	image: IGatsbyImageData;
	imageAlt: string;
	title: string;
	content: string | string[];
	reverse?: boolean;
}

export default (props: ContentImageProps) => (
	<ContentImage image={props?.image} imageAlt={props?.imageAlt} reverse={props?.reverse}>
		<TextContainer direction="column" reverse={props?.reverse}>
			<Typography variant="h2" paragraph>
				{props?.title}
			</Typography>
			<Paragraphs content={props?.content} />
		</TextContainer>
	</ContentImage>
);
