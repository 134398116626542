import React from 'react';
import { styled } from '@mui/material';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import Flex from 'components/Flex';

const ImageContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'reverse' })<{
	reverse?: boolean;
}>(({ theme, reverse }) => ({
	padding: reverse ? theme.spacing(4, 4, 0, 0) : theme.spacing(4, 0, 0, 4),
}));

const Image = styled(GatsbyImage, { shouldForwardProp: (prop) => prop !== 'reverse' })<{
	reverse?: boolean;
}>(({ theme, reverse }) => ({
	maxWidth: 500,
	maxHeight: 600,
	flex: '1 1 0',
	boxShadow: reverse
		? `${theme.spacing(4, 4, 0, 0)} ${theme.palette.tertiary.dark}`
		: `${theme.spacing(-4, 4, 0, 0)} ${theme.palette.tertiary.main}`,
	[theme.breakpoints.down('lg')]: {
		maxWidth: 300,
	},
	[theme.breakpoints.down('md')]: {
		flex: 1,
		maxWidth: 500,
		maxHeight: 400,
		width: '100%',
	},
}));

const ContentContainer = styled(Flex, { shouldForwardProp: (prop) => prop !== 'reverse' })<{
	reverse?: boolean;
}>(({ theme, reverse }) => ({
	width: '100%',
	maxWidth: 1400,
	marginBottom: theme.spacing(5),
	flexDirection: reverse ? 'row-reverse' : 'row',
	[theme.breakpoints.down('md')]: {
		flexDirection: 'column',
		alignItems: 'center',
		margin: theme.spacing(3, 0, 5),
	},
}));

interface Props {
	image: IGatsbyImageData;
	imageAlt: string;
	children: React.ReactNode;
	reverse?: boolean;
}

export default (props: Props) => (
	<ContentContainer justify="space-evenly" align="center" reverse={props?.reverse}>
		{props?.children}
		<ImageContainer reverse={props?.reverse}>
			<Image image={props?.image} alt={props?.imageAlt} reverse={props?.reverse} />
		</ImageContainer>
	</ContentContainer>
);
