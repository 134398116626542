import React from 'react';
import { styled, Typography } from '@mui/material';

import Flex from 'components/Flex';

const ServiceRoot = styled(Flex)(({ theme }) => ({
	maxWidth: 400,
	margin: theme.spacing(2, 4),
	background: theme.palette.primary.main,
	borderRadius: 10,
	padding: theme.spacing(4),
	boxShadow: theme.shadows[2],
	[theme.breakpoints.down('md')]: {
		maxWidth: '100%',
		margin: theme.spacing(2),
	},
}));

const IconContainer = styled('div')(({ theme }) => ({
	width: 100,
	height: 100,
	marginBottom: theme.spacing(2),
	borderRadius: 100,
	background: theme.palette.background.paper,
	display: 'grid',
	placeItems: 'center',
}));

const Text = styled(Typography)({
	color: 'white',
});

const Content = styled(Text)({
	flex: 1,
});

const Icon = styled('img')({
	flex: 1,
	fill: '#2d2d2d',
	maxHeight: 50,
	maxWidth: 50,
	width: '100%',
});

export interface Props {
	icon: string;
	title: string;
	content: string;
}

export default (props: Props) => (
	<ServiceRoot direction="column" align="center" justify="center">
		<IconContainer>
			<Icon src={props?.icon} />
		</IconContainer>
		<Text variant="h3" paragraph textAlign="center">
			{props?.title}
		</Text>
		<Content textAlign="center">{props?.content}</Content>
	</ServiceRoot>
);
