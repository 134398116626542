import React, { useState, useEffect } from 'react';
import { styled, Typography, Collapse } from '@mui/material';

import Flex from 'components/Flex';
import Button from 'components/Hero/Button';
import LeftAlignedContainer from 'components/LeftAlignedContainer';

const ContentRoot = styled(Flex)({
	maxWidth: 850,
	marginTop: 106,
});

const Text = styled(Typography, { shouldForwardProp: (prop) => prop !== 'margin' })<{
	margin: number;
}>(({ theme, margin }) => ({
	color: 'white',
	marginBottom: theme.spacing(margin),
}));

const Title = styled(Text)({
	fontWeight: 800,
});

export interface ContentProps {
	title?: string;
	subtitle?: string;
	content?: string | string[];
}

export default (props: ContentProps) => {
	const [titleIn, setTitleIn] = useState(false);
	const [subtitleIn, setSubtitleIn] = useState(false);
	const [buttonIn, setButtonIn] = useState(false);

	const renderContent = () => {
		if (props?.content) {
			if (Array.isArray(props?.content)) {
				return props?.content?.map((string) => (
					<Text margin={2} key={string}>
						{string}
					</Text>
				));
			}
			return <Text margin={2}>{props?.content}</Text>;
		}
		return null;
	};

	useEffect(() => {
		setTimeout(() => {
			setTitleIn(true);
		}, 500);

		setTimeout(() => {
			setSubtitleIn(true);
		}, 1000);

		setTimeout(() => {
			setButtonIn(true);
		}, 1500);
	}, []);

	return (
		<ContentRoot direction="column">
			<LeftAlignedContainer navMargin>
				<Collapse in={titleIn}>
					<Title variant="h1" margin={3}>
						{props?.title}
					</Title>
				</Collapse>
				<Collapse in={subtitleIn}>
					<Text variant="subtitle1" margin={2}>
						{props?.subtitle}
					</Text>
				</Collapse>
				{renderContent()}
				<Collapse in={buttonIn}>
					<Button />
				</Collapse>
			</LeftAlignedContainer>
		</ContentRoot>
	);
};
