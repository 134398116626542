import React from 'react';
import { styled } from '@mui/material';

import { ReactComponent as PhoneIcon } from 'content/images/icons/phone.svg';
import { ReactComponent as EmailIcon } from 'content/images/icons/envelope.svg';
import { ReactComponent as HomeIcon } from 'content/images/icons/home.svg';
import { ReactComponent as PhoneOfficeIcon } from 'content/images/icons/phone-office.svg';

const Home = styled(HomeIcon, {
	shouldForwardProp: (prop) => prop !== 'small' && prop !== 'dark',
})<{ small?: boolean; dark?: boolean }>(({ theme, dark, small }) => ({
	width: small ? 10 : 20,
	marginRight: small ? theme.spacing(1) : theme.spacing(2),
	fill: dark ? theme.palette.text.primary : 'white',
	alignSelf: 'flex-start',
	marginTop: small ? 0 : 4,
}));

const Email = styled(EmailIcon, {
	shouldForwardProp: (prop) => prop !== 'small' && prop !== 'dark',
})<{
	small?: boolean;
	dark?: boolean;
}>(({ theme, dark, small }) => ({
	width: small ? 10 : 20,
	marginRight: small ? theme.spacing(1) : theme.spacing(2),
	fill: dark ? theme.palette.text.primary : 'white',
	marginTop: small ? 0 : 2,
}));

const Phone = styled(PhoneIcon, {
	shouldForwardProp: (prop) => prop !== 'small' && prop !== 'dark',
})<{
	small?: boolean;
	dark?: boolean;
}>(({ theme, dark, small }) => ({
	width: small ? 10 : 20,
	marginRight: small ? theme.spacing(1) : theme.spacing(2),
	fill: dark ? theme.palette.text.primary : 'white',
	marginTop: small ? 0 : 2,
}));

const PhoneOffice = styled(PhoneOfficeIcon, {
	shouldForwardProp: (prop) => prop !== 'small' && prop !== 'dark',
})<{
	small?: boolean;
	dark?: boolean;
}>(({ theme, dark, small }) => ({
	width: small ? 10 : 20,
	marginRight: small ? theme.spacing(1) : theme.spacing(2),
	fill: dark ? theme.palette.text.primary : 'white',
	marginTop: small ? 0 : 2,
}));

export interface IconProps {
	type: 'address' | 'phone' | 'email' | 'phoneOffice';
	small?: boolean;
	dark?: boolean;
}

export default (props: IconProps) => {
	switch (props?.type) {
		case 'email': {
			return <Email small={props?.small} dark={props?.dark} />;
		}
		case 'phone': {
			return <Phone small={props?.small} dark={props?.dark} />;
		}
		case 'phoneOffice': {
			return <PhoneOffice small={props?.small} dark={props?.dark} />;
		}
		default: {
			return <Home small={props?.small} dark={props?.dark} />;
		}
	}
};
