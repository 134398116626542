import React from 'react';
import { styled } from '@mui/material';
import { GatsbyImage } from 'gatsby-plugin-image';

import type { GalleryItemNode } from 'components/Gallery/types/GalleryItemNode';
import Flex from 'components/Flex';

const Image = styled(GatsbyImage, { shouldForwardProp: (prop) => prop !== 'active' })<{
	active?: boolean;
}>(({ theme, active }) => ({
	width: 142,
	height: 142,
	opacity: active ? '100%' : '40%',
	cursor: 'pointer',
	margin: theme.spacing(0, 0, 2, 2),
	[theme.breakpoints.down(1240)]: {
		width: 150,
		height: 150,
	},
}));

const ThumbnailGalleryRoot = styled(Flex)(({ theme }) => ({
	margin: theme.spacing(3, 0, 0, -2),
	[theme.breakpoints.down(1240)]: {
		justifyContent: 'space-between',
	},
	[theme.breakpoints.down('md')]: {
		padding: theme.spacing(0, 3),
		flexWrap: 'nowrap',
		justifyContent: 'flex-start',
	},
}));

export interface ThumbnailGalleryProps {
	activeIndex: number;
	setActiveIndex: (index: number) => void;
}

interface Props extends ThumbnailGalleryProps {
	nodes: GalleryItemNode[];
}

export default (props: Props) => {
	const renderImages = () =>
		props?.nodes?.map((node, index) => (
			<span onClick={() => props?.setActiveIndex(index)} role="button">
				<Image
					image={node?.galleryItem?.image?.localFile?.childImageSharp?.gatsbyImageData}
					alt={node?.galleryItem?.image?.altText}
					key={`${node?.galleryItem?.image?.id}thumbnail`}
					active={index === props?.activeIndex}
				/>
			</span>
		));

	return <ThumbnailGalleryRoot wrap>{renderImages()}</ThumbnailGalleryRoot>;
};
