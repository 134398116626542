import React from 'react';
import { styled, IconButton } from '@mui/material';
import { keyframes } from '@emotion/react';

import { ReactComponent as ArrowDownIcon } from 'content/images/icons/arrow-down.svg';

const myKeyframe = keyframes`
    0% { -webkit-transform: translateY(0); opacity: 0.8 },
  100% { -webkit-transform: translateY(0.4em); opacity: 1 }
`;

const ArrowDown = styled(ArrowDownIcon)(({ theme }) => ({
	width: 50,
	fill: 'white',
	marginTop: theme.spacing(1),
	animation: `${myKeyframe} 2s infinite alternate ease-in-out`,
}));

export default () => (
	<IconButton>
		<ArrowDown />
	</IconButton>
);
