import React from 'react';
import { styled } from '@mui/material';

import Flex from 'components/Flex';

const LeftAlignedContainer = styled(Flex, { shouldForwardProp: (prop) => prop !== 'navMargin' })<{
	navMargin?: boolean;
}>(({ theme, navMargin }) => ({
	marginLeft: theme.spacing(20),
	[theme.breakpoints.down('md')]: {
		marginLeft: theme.spacing(10),
	},
	[theme.breakpoints.down('sm')]: {
		marginLeft: 0,
		marginBottom: navMargin ? theme.spacing(6) : 0,
	},
	[theme.breakpoints.down('xs')]: {
		marginBottom: navMargin ? theme.spacing(4) : 0,
	},
}));

interface Props {
	children: React.ReactNode;
	navMargin?: boolean;
}

export default (props: Props) => (
	<LeftAlignedContainer direction="column" navMargin={props?.navMargin}>
		{props?.children}
	</LeftAlignedContainer>
);
