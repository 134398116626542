import React from 'react';
import { styled } from '@mui/material';

import Flex from 'components/Flex';
import Icon, { IconProps } from 'components/IconText/Icon';
import Text, { TextProps } from 'components/IconText/Text';

const LogoParagraphRoot = styled(Flex)(({ theme }) => ({
	overflow: 'hidden',
	paddingBottom: theme.spacing(2),
	[theme.breakpoints.down('sm')]: {
		alignItems: 'center',
	},
}));

const Link = styled('a', { shouldForwardProp: (prop) => prop !== 'dark' })<{ dark?: boolean }>(
	({ theme, dark }) => ({
		textDecoration: 'none',
		position: 'relative',
		'&::after': {
			content: '""',
			position: 'absolute',
			bottom: -4,
			left: 0,
			width: '100%',
			height: 3,
			backgroundColor: dark ? theme.palette.primary.main : 'white',
			transition: 'opacity 300ms, transform 300ms',
			transform: 'translate3d(-100%, 0, 0)',
			opacity: 0,
		},
		'&:hover::after, :focus::after': {
			transform: 'translate3d(0, 0, 0)',
			opacity: 1,
		},
	}),
);

interface Props extends IconProps, TextProps {
	className?: string;
	link?: string;
}

export default (props: Props) => (
	<LogoParagraphRoot className={props?.className} defaultFlex>
		<Icon small={props?.small} type={props?.type} dark={props?.dark} />
		<Link href={props?.link}>
			<Text small={props?.small} content={props?.content} dark={props?.dark} />
		</Link>
	</LogoParagraphRoot>
);
