import React from 'react';
import { styled } from '@mui/material';
import { GatsbyImage } from 'gatsby-plugin-image';

import Carousel from 'components/Carousel';
import type { GalleryItemNode } from 'components/Gallery/types/GalleryItemNode';
import ThumbnailGallery, {
	ThumbnailGalleryProps,
} from 'components/Gallery/Content/ThumbnailGallery';
import Flex from 'components/Flex';
import TitleSubtitle from 'components/TitleSubtitle';

const Text = styled(TitleSubtitle)(({ theme }) => ({
	marginTop: theme.spacing(2),
	[theme.breakpoints.down('lg')]: {
		// padding: theme.spacing(0, 5),
	},
	[theme.breakpoints.down('md')]: {
		padding: theme.spacing(0, 3),
	},
}));

const Image = styled(GatsbyImage)(({ theme }) => ({
	height: 500,
	[theme.breakpoints.down('sm')]: {
		height: 'auto',
	},
}));

const ContentRoot = styled(Flex)({
	maxWidth: 1450,
});

const GalleryContainer = styled(Flex)(({ theme }) => ({
	[theme.breakpoints.down('md')]: {
		overflowX: 'scroll',
	},
}));

const ThumbnailTextContainer = styled(Flex)(({ theme }) => ({
	padding: '0 180px',
	[theme.breakpoints.down('md')]: {
		padding: 0,
	},
}));

interface Props extends ThumbnailGalleryProps {
	nodes: GalleryItemNode[];
}

export default (props: Props) => {
	const sliderRef = 'gallerySlider';

	console.log(props);

	const renderImages = () =>
		props?.nodes?.map((image) => (
			<Image
				image={image?.galleryItem?.image?.localFile?.childImageSharp?.gatsbyImageData}
				alt={image?.galleryItem?.image?.altText}
				key={image?.galleryItem?.image?.id}
			/>
		));

	return (
		<ContentRoot direction="column" width>
			<Carousel
				sliderRef={sliderRef}
				goToIndex={props?.activeIndex}
				noAutoplay
				noMinHeight
				setActiveIndex={props?.setActiveIndex}
			>
				{renderImages()}
			</Carousel>
			<ThumbnailTextContainer direction="column">
				<Text
					title={props?.nodes[props?.activeIndex]?.title}
					subtitle={props.nodes[props?.activeIndex]?.galleryItem?.subtitle}
					line
					flexStart
				/>
				<GalleryContainer width>
					<ThumbnailGallery
						nodes={props?.nodes}
						activeIndex={props?.activeIndex}
						setActiveIndex={props?.setActiveIndex}
					/>
				</GalleryContainer>
			</ThumbnailTextContainer>
		</ContentRoot>
	);
};
