import React from 'react';
import { styled } from '@mui/material';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import Content, { ContentProps } from 'components/Hero/Content';
import DarkenedBackgroundImage from 'components/DarkenedBackgroundImage';

const HeroRoot = styled(DarkenedBackgroundImage)({
	height: '100vh',
});

const Link = styled(AnchorLink)({
	textDecoration: 'none',
});

interface Props extends ContentProps {
	backgroundImage: IGatsbyImageData;
}

export default (props: Props) => (
	<Link to="/#about">
		<HeroRoot image={props?.backgroundImage} navPadding>
			<Content title={props?.title} subtitle={props?.subtitle} content={props?.content} />
		</HeroRoot>
	</Link>
);
